@import url("https://fonts.googleapis.com/css2?family=Allura&family=Great+Vibes&family=Pacifico&family=Pinyon+Script&family=Roboto:wght@500&family=Rubik+Mono+One&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: #000;
}

html {
  scroll-behavior: smooth;
}

.header {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 10;
  transition: all 0.8s;
}

.colorChange {
  background-color: rgba(235, 235, 235, 0.849);
}


